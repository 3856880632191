import { faArrowRight, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Menu, MenuProps } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { useAuthContext } from "logic/auth-context";
import { USER_ROUTE } from "templates/user/user-routes";
import { WORKSPACE_PLANS_ROUTE } from "templates/workspace-plans/workspace-plans-routes";
import { ProjectInviteStatus } from "store/modules/projects/types";
import { WORKSPACE_SIGNUP_ROUTE } from "templates/workspace-settings/workspace-settings.routes";
import { useSidebarMenuContext } from "../context";

export enum BottomMenuKeys {
  PROFILE = "profile",
  START_FREE_TRIAL = "start-free-trial",
  PENDING_INVITES = "pending-invites",
  SSE_CONNECTION_STATUS = "sse-connection-status",
}

type BottomMenuProps = {
  collapsed?: boolean;
};

export const BottomMenu: React.FC<BottomMenuProps> = ({ collapsed }) => {
  const auth = useAuthContext();
  // Router
  const router = useRouter();

  const { sseConnectionStatusEnabled } = useSidebarMenuContext();

  const activeSubscription = useAppSelector(
    (state) => state.workspaces.data.workspace?.activeSubscription
  );
  const workspaces = useAppSelector((state) => state.workspaces.data);

  const projectSseConnected = useAppSelector((state) => state.projects.data.projectSseConnected);

  const sseConnectionStatus = useMemo(() => {
    if (sseConnectionStatusEnabled === undefined)
      return {
        label: "idle",
        color: "yellow",
      };
    return projectSseConnected
      ? {
          label: "connected",
          color: "green",
        }
      : { label: "disconnected", color: "red" };
  }, [projectSseConnected, sseConnectionStatusEnabled]);

  const isSignedIn = useMemo(() => auth.session.user, [auth.session.user]);

  const hasPendingInvites = useMemo(
    () =>
      !!workspaces.workspacesList?.filter(
        (workspace) => workspace.status === ProjectInviteStatus.Pending
      )?.length,
    [workspaces.workspacesList]
  );

  // Bottom Menu Items
  const bottomMenuItems: MenuProps["items"] = useMemo(() => {
    // center items styles
    const itemStyles = {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    };

    // Feedback menu item

    const sseConnectionStatusItem: ItemType = {
      label: (
        <div style={itemStyles}>
          <span style={{ color: sseConnectionStatus.color }}>{sseConnectionStatus.label}</span>
        </div>
      ),
      disabled: true,
      key: BottomMenuKeys.SSE_CONNECTION_STATUS,
    };

    const pendingInvites: ItemType = {
      label: (
        <div style={itemStyles}>
          <Button type="primary" danger>
            You have pending invites
          </Button>
        </div>
      ),
      key: BottomMenuKeys.PENDING_INVITES,
    };

    const seePlans: ItemType = {
      label: (
        <div style={itemStyles}>
          <span>See plans</span>
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      ),
      key: BottomMenuKeys.START_FREE_TRIAL,
    };

    // Profile menu item
    const profile: ItemType = {
      label: (
        <div style={itemStyles}>
          <span>{USER_ROUTE.label}</span>
        </div>
      ),
      key: BottomMenuKeys.PROFILE,
      icon: <FontAwesomeIcon icon={faUser} />,
    };

    // Compose items by order
    const items = [];

    if (sseConnectionStatusEnabled) items.push(sseConnectionStatusItem);

    // pending invites
    if (hasPendingInvites && !collapsed) items.push(pendingInvites);

    // see plans
    if (isSignedIn && !activeSubscription && !collapsed) items.push(seePlans);

    // sign up / profile
    if (isSignedIn) {
      items.push(profile);
    }

    return items;
  }, [
    isSignedIn,
    activeSubscription,
    hasPendingInvites,
    collapsed,
    sseConnectionStatusEnabled,
    sseConnectionStatus,
  ]);

  // Top Menu Items Selected Keys
  const bottomMenuItemsSelectedKeys = useMemo(() => {
    const isProfile = router.asPath.startsWith(USER_ROUTE.path);

    const items = [];
    if (isSignedIn && !activeSubscription) items.push(BottomMenuKeys.START_FREE_TRIAL);
    if (isProfile) items.push(BottomMenuKeys.PROFILE);

    return items;
  }, [router.asPath, activeSubscription, isSignedIn]);

  // Menu Select Handler
  const onMenuSelect = useCallback(
    (key: string) => {
      switch (key) {
        case BottomMenuKeys.PROFILE:
          analytics.track("workspace:sidebar:account-settings");
          router.push(USER_ROUTE.getHref());
          break;
        case BottomMenuKeys.START_FREE_TRIAL:
          analytics.track("workspace:sidebar:workspace-plans");
          router.push(WORKSPACE_PLANS_ROUTE.getHref());
          break;
        case BottomMenuKeys.PENDING_INVITES:
          analytics.track("workspace:sidebar:workspace-plans");
          router.push(WORKSPACE_SIGNUP_ROUTE.getHref());
          break;
      }
    },
    [router]
  );

  return (
    <div
      style={{
        position: "absolute",
        bottom: "0",
        width: "100%",
      }}
    >
      <style jsx global>
        {`
          .ant-menu-title-content {
            overflow: visible !important;
          }
        `}
      </style>
      <Menu
        selectedKeys={bottomMenuItemsSelectedKeys}
        className="layout-menu"
        theme="dark"
        style={{ backgroundColor: "transparent" }}
        mode="inline"
        items={bottomMenuItems}
        onClick={(info) => onMenuSelect(info.key)}
      />
    </div>
  );
};
