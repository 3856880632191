import { PLANS_ROUTE } from "@/templates/plans/plans.routes";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { RAG_ROUTE } from "templates/rag/rag.routes";
import { STUDY_ROUTE } from "templates/studies/study.routes";

enum TutorialIds {
  RAG = "68f-gs0pV6M",
  RESEARCH_ASSISTANT = "cTD4SEAlr-I",
  STUDY = "BxqWudgR5zw",
  PRISMA = "Y_EfmZXGeWA",
}

export function useGetCurrentPathTutorial() {
  const router = useRouter();
  const pathname = usePathname();
  const { studyId } = router.query;

  const currentPathTutorialId: string | undefined = useMemo(() => {
    if (pathname.startsWith(RAG_ROUTE.path)) {
      return TutorialIds.RAG;
    }

    if (pathname.startsWith(STUDY_ROUTE.path)) {
      const existingStudy = !!studyId;
      if (existingStudy) {
        return TutorialIds.RESEARCH_ASSISTANT;
      } else {
        return TutorialIds.STUDY;
      }
    }

    if (pathname.startsWith(PLANS_ROUTE.path)) {
      return TutorialIds.PRISMA;
    }
  }, [pathname, studyId]);

  return { currentPathTutorialId };
}
