// React
import { useContext, createContext } from "react";

export type SidebarMenuContextProps = {
  sidebarMenuCollapsed?: boolean;
  setSidebarMenuCollapsed: () => void;
  ragEnabled?: boolean;
  surveysEnabled?: boolean;
  plansEnabled?: boolean;
  isImpersonating?: boolean;
  sseConnectionStatusEnabled?: boolean;
};

const SidebarMenuContextDefaults: SidebarMenuContextProps = {
  sidebarMenuCollapsed: undefined,
  setSidebarMenuCollapsed: () => {
    throw new Error(
      "to use this context, please wrap your component with SidebarMenuContextProvider"
    );
  },
  ragEnabled: undefined,
  surveysEnabled: undefined,
  plansEnabled: undefined,
  isImpersonating: undefined,
  sseConnectionStatusEnabled: undefined,
};

// Context
export const SidebarMenuContext = createContext<SidebarMenuContextProps>(
  SidebarMenuContextDefaults
);

// Hook
export const useSidebarMenuContext = (): SidebarMenuContextProps => useContext(SidebarMenuContext);
