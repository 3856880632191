// store
import { store } from "store";
// slices
import { resetResearchAssistant } from "store/modules/research-assistant/slice";
import { resetSelectedAudiences } from "store/modules/audiences/slice";
import { resetSelectedProblems } from "store/modules/problems/slice";
import { resetSelectedSolution } from "store/modules/solutions/slice";
import { resetResearchGoal } from "store/modules/research-goal/slice";
import { resetCustomScript } from "store/modules/custom-script/slice";
import { resetSyntheticUsers } from "store/modules/synthetic-users/slice";
import { resetUserInterviews } from "store/modules/user-interviews/slice";
import { resetSelectedFiles } from "@/store/modules/files/slice";
import { resetKnowledgeGraph } from "store/modules/knowledge-graph/slice";
import { resetSummary } from "store/modules/summaries/slice";
import { resetCurrentStudy } from "store/modules/studies/slice";
import { resetStudyPlan } from "store/modules/study-plans/slice";

export const handleNewStudy = () => {
  store.dispatch(resetStudyPlan());
  store.dispatch(resetResearchAssistant());
  store.dispatch(resetSelectedAudiences());
  store.dispatch(resetSelectedProblems());
  store.dispatch(resetSelectedSolution());
  store.dispatch(resetResearchGoal());
  store.dispatch(resetCustomScript());
  store.dispatch(resetCurrentStudy());
  store.dispatch(resetKnowledgeGraph());
  store.dispatch(resetSelectedFiles());
  store.dispatch(resetSyntheticUsers());
  store.dispatch(resetUserInterviews());
  store.dispatch(resetSummary());
};
