import { AntIcon } from "@/components/ui-kit/components/ant-icon";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { Modal, Typography } from "antd";
import { FC, useState } from "react";
import { useGetCurrentPathTutorial } from "@/logic/hooks/get-current-path-tutorial";
import { useAppSelector } from "@/store/hooks";

export const Tutorial: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const workspace = useAppSelector((state) => state.workspaces.data?.workspace);

  const { currentPathTutorialId } = useGetCurrentPathTutorial();

  if (!currentPathTutorialId || !workspace) return null;

  return (
    <>
      <div
        onClick={() => setIsOpen(true)}
        className="flex items-center gap-2 py-1.5 px-2 rounded-full border border-[#555690] cursor-pointer text-[#555690] hover:bg-[#555690] hover:text-white transition-all "
      >
        <Typography.Text style={{ fontSize: 12, color: "inherit", lineHeight: 1 }}>
          Watch Tutorial
        </Typography.Text>
        <AntIcon icon={faPlay} />
      </div>
      <Modal
        destroyOnClose
        width="90vw"
        style={{
          maxWidth: "1200px",
        }}
        styles={{
          body: {
            padding: 0,
            overflow: "hidden",
          },
        }}
        closeIcon={null}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        centered
      >
        <div
          style={{
            position: "relative",
            paddingTop: "56.25%", // Slightly reduced to account for header
            width: "100%",
          }}
        >
          <iframe
            src={`https://www.youtube.com/embed/${currentPathTutorialId}?autoplay=1`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              border: "none",
            }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Modal>
    </>
  );
};
