import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { z } from "zod";
import {
  WorkspaceMemberSchema,
  WorkspaceSchema,
  WorkspaceListItemSchema,
  WorkspaceUsageDashboardSchema,
  WorkspaceInviteResponseSchema,
  PaginatedWorkspacesSchema,
} from "./schemas";

export type Workspace = z.infer<typeof WorkspaceSchema>;
export type PaginatedWorkspaces = z.infer<typeof PaginatedWorkspacesSchema>;
export type WorkspacesListItem = z.infer<typeof WorkspaceListItemSchema>;
export type WorkspaceMember = z.infer<typeof WorkspaceMemberSchema>;
export type WorkspaceUsageDashboard = z.infer<typeof WorkspaceUsageDashboardSchema>;
export type WorkspaceInviteResponse = z.infer<typeof WorkspaceInviteResponseSchema>;

export enum WorkspaceMode {
  Regular = "regular",
  SavedStudy = "saved-study",
}

export enum WorkspaceInviteStatus {
  Accepted = "accepted",
  Pending = "pending",
  Rejected = "rejected",
}

export enum WorkspaceMemberRole {
  Owner = "owner",
  Member = "member",
}

export enum WorkspaceMemberStatus {
  Active = "active",
  Pending = "pending",
  Inactive = "inactive",
}

export type StateProps = {
  loading: boolean;
  error?: TransportFailure;
  /*
    the idea of loaded data is represented by the truthy value of data,
    hence the need for a string signaling that past interviews were indeed requested
    but there were no past interviews
  */
  data: {
    workspacesList?: WorkspacesListItem[];
    workspaceMembers?: WorkspaceMember[];
    workspace?: Workspace;
    workspaceUsageDashboard?: WorkspaceUsageDashboard;
    workspaceMode?: WorkspaceMode;
  };
};
