import {
  faArrowRight,
  faChevronDown,
  faFolder,
  faGear,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { App as AntApp, Dropdown, Flex, MenuProps, Space } from "antd";
import { useAuthContext } from "logic/auth-context";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { useLazyGetProjectByIdQuery } from "store/modules/projects/slice";
import { INDEX_ROUTE } from "templates/index/index-routes";
import { PROJECT_SETTINGS_ROUTE } from "templates/project-settings/project-settings.routes";
import { PROJECTS_ROUTE } from "templates/projects/projects.routes";

const ADD_PROJECT_ITEM_ID = "add-project";
const SEE_ALL_PROJECTS_ITEM_ID = "see-all-projects";

export const ProjectsMenu: React.FC = () => {
  const projects = useAppSelector((state) => state.projects.data);
  const projectsList = useMemo(() => projects?.projectsList, [projects?.projectsList]);
  const project = useMemo(() => projects?.project, [projects?.project]);
  const router = useRouter();

  const auth = useAuthContext();
  const user = auth.session.user;
  const workspace = useAppSelector((state) => state.workspaces.data.workspace);
  const projectId = useAppSelector((state) => state.projects.data.project?.id);

  const [getProjectById] = useLazyGetProjectByIdQuery();

  const isWorkspaceOwner = useMemo(
    () => workspace?.owners?.includes(user?.uid ?? ""),
    [user?.uid, workspace]
  );

  const { message } = AntApp.useApp();

  const items: Required<MenuProps>["items"] = useMemo(
    () => [
      ...(projectsList
        ? [
            {
              key: "projects",
              label: "Projects",
              type: "group",
              children: projectsList.map((item) => ({
                label: (
                  <Flex
                    justify="space-between"
                    align="center"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    className="menu-item"
                  >
                    <span style={{ marginRight: 16 }}>{item.name}</span>
                    <FontAwesomeIcon
                      icon={faGear}
                      style={{
                        cursor: "pointer",
                        padding: 8,
                        borderRadius: 4,
                      }}
                      className={`menu-item-icon ${item.id === project?.id ? "active" : ""}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        router.push(PROJECT_SETTINGS_ROUTE.getHref(item.id));
                      }}
                    />
                  </Flex>
                ),
                key: item.id,
                onClick: async () => {
                  if (item.id === projectId) return;
                  await router.push(INDEX_ROUTE.getHref());
                  try {
                    const newProject = await getProjectById({
                      projectId: item.id,
                    }).unwrap();
                    message.info(`You are now in ${newProject.name || ""} project`);
                  } catch (error) {
                    message.error("Failed to get project");
                  }
                },
              })),
            },
          ]
        : []),
      {
        type: "divider",
      },
      ...(isWorkspaceOwner
        ? [
            {
              label: (
                <Space
                  align="center"
                  style={{ width: "100%", justifyContent: "space-between" }}
                  onClick={() => {
                    router.push(PROJECT_SETTINGS_ROUTE.path);
                  }}
                >
                  <span style={{ marginRight: 16 }}>Create New Project</span>
                  <FontAwesomeIcon icon={faPlus} style={{ padding: 8 }} />
                </Space>
              ),
              key: ADD_PROJECT_ITEM_ID,
            },
          ]
        : []),
      {
        label: (
          <Space
            align="center"
            style={{ width: "100%", justifyContent: "space-between" }}
            onClick={() => {
              router.push(PROJECTS_ROUTE.path);
            }}
          >
            <span>See All Projects</span>
            <FontAwesomeIcon icon={faArrowRight} style={{ padding: 8 }} />
          </Space>
        ),
        key: SEE_ALL_PROJECTS_ITEM_ID,
      },
    ],
    [projectsList, router, project?.id, isWorkspaceOwner, message, projectId, getProjectById]
  );

  return (
    <Dropdown
      trigger={["click"]}
      placement="bottomLeft"
      overlayClassName="breadcrumbs-menu"
      menu={{
        selectable: true,
        selectedKeys: [project?.id || ""],
        items,
      }}
    >
      <Space align="center" style={{ cursor: "pointer" }} className="menu-wrapper">
        <FontAwesomeIcon icon={faFolder} color="#000000" />
        <span>{project?.name}</span>
        <FontAwesomeIcon icon={faChevronDown} size="2xs" color="#000000" />
      </Space>
    </Dropdown>
  );
};
